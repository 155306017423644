import React from "react";
// import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
// import { GiRibbonMedal, GiHammerNails } from "react-icons/gi";
// import { AiOutlineTeam } from "react-icons/ai";
// import { IoMdHammer } from "react-icons/io";
function HeroVideo({ urlVideo, title, texts, name }) {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.3,
    });
    // const { rpdata } = useContext(GlobalDataContext);
    return (
        <div>
            <div className="w-full relative content_video flex justify-center">
                <video

                    playsInline
                    autoPlay
                    muted
                    loop
                    className="w-full md:h-full object-cover"
                >
                    <source
                        src={urlVideo}
                        type="video/mp4"
                    />
                </video>
                <div className="flex md:flex-row flex-col md:w-4/5 mx-auto absolute bottom-[30px] md:bottom-[100px] lg:bottom-[120px] md:text-start text-center text-white">
                    <div className="w-full text-center md:text-start p-4">
                        <p className="font-semibold text-[20px] md:text-[1.5em] lg:text-[1.5em]">Welcome To:</p>
                        <h1 className="text-[30px] md:text-[3em] lg:text-[3.2em]">{name}</h1>
                        <h2 className="text-[20px] md:text-[1.5em] lg:text-[1.5em] text-white">{title}</h2>

                        <ButtonContent btnStyle="three" />
                    </div>
                    <motion.img
                        ref={ref}
                        initial={{ x: "100%", opacity: 0, rotate: 90 }}
                        animate={inView ? { x: 0, opacity: 1, rotate: 0 } : {}}
                        transition={{ duration: 2.5, ease: "easeOut" }}
                        src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/A%C3%B1os%20de%20experiencia%2F25%20A%C3%B1os.png?alt=media&token=181aee51-aad3-4baa-adf1-79ca117ed639"
                        alt="years"
                        className="w-[200px] md:w-[300px] h-auto md:mt-0 mt-5 mx-auto md:mx-0"
                    />
                </div>
            </div>
            {/* <div className=" md:w-[70%] w-[100%] bg-2 text-white md:-mt-14 -mt-1 relative md:py-14 py-2 homi">
                    <ul className="flex md:flex-row flex-col justify-around ml-5 md:ml-0">
                        <h5 className="flex items-center" data-aos="zoom-in">
                            <GiHammerNails
                                fontSize={70}
                                className="text-white"
                            />
                            BEST SERVICE</h5>
                        <h5 className="flex items-center" data-aos="zoom-in" data-aos-duration="1000">
                            <AiOutlineTeam
                                fontSize={70}
                                className="text-white"
                            />
                            PROFESSIONAL TEAM</h5>
                        <h5 className="flex items-center" data-aos="zoom-in" data-aos-duration="1500">
                            <GiRibbonMedal
                                fontSize={70}
                                className="text-white"
                            />BEST QUALITY</h5>
                    </ul>
                </div> */}
        </div>
    );
}
export default HeroVideo;